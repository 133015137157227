
import { getInfo } from '@/api/nice';
import { Vue, Component } from 'vue-property-decorator';
import { parseConcatNumber } from '@/filters';
import moment from 'moment';

@Component({
  name: 'NiceAccept',
})
export default class extends Vue {
  mounted() {
    getInfo(String(this.$route.query.EncodeData)).then((res) => {
      window.opener.document.getElementById('actualName').value = res.data.name;
      window.opener.document.getElementById('concatNumber').value = parseConcatNumber(res.data.phone);

      const birth = moment(res.data.birth, 'YYYYMMDD').format('YYYY-MM-DD');
      window.opener.document.getElementById('birth').value = birth;

      window.close();
    });
  }
}
